import queryString from 'query-string';
import crypto from 'crypto';

export const buildEmbedLookerUrl = () => {
  const embedUrl = process.env.REACT_APP_LOOKER_EMBED;
  const lookerSecret = process.env.REACT_APP_LOOKER_SECRET;
  const lookerUrl = process.env.REACT_APP_LOOKER_URL;
  const lookerUser = {
    id: process.env.REACT_APP_LOOKER_USER,
    email: process.env.REACT_APP_LOOKER_USER,
  };
  const thirtyDaysInSeconds = 2592000;

  const url = createdSignedEmbedUrl({
    host: lookerUrl,
    secret: lookerSecret,
    external_user_id: lookerUser.id,
    group_ids: [86],
    permissions: [], // permissions are handled via the Looker API
    models: ['Pace_Analytics'],
    access_filters: {},
    first_name: 'Preview Account',
    user_attributes: {
      property_group_id: process.env.REACT_APP_PROPERTY_GROUP_ID,
      email: lookerUser.email,
    },
    session_length: thirtyDaysInSeconds,
    embed_url: embedUrl,
    force_logout_login: true,
  });

  return url;
};

const generateNonce = (len) => {
  let text = '';
  let possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < len; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

const forceUnicodeEncoding = (string) => {
  return decodeURIComponent(encodeURIComponent(string));
};

export const createdSignedEmbedUrl = (options) => {
  // looker options
  let secret = options.secret;
  let host = options.host;

  // user options
  let json_external_user_id = JSON.stringify(options.external_user_id);
  let json_permissions = JSON.stringify(options.permissions);
  let json_models = JSON.stringify(options.models);
  let json_group_ids = JSON.stringify(options.group_ids);
  let json_user_attributes = JSON.stringify(options.user_attributes || {});
  let json_access_filters = JSON.stringify(options.access_filters);
  let json_first_name = JSON.stringify(options.first_name);
  // url/session specific options
  let embed_path = '/login/embed/' + encodeURIComponent(options.embed_url);
  let json_session_length = JSON.stringify(options.session_length);
  let json_force_logout_login = JSON.stringify(options.force_logout_login);

  // computed options
  let json_time = JSON.stringify(Math.floor(new Date().getTime() / 1000));
  let json_nonce = JSON.stringify(generateNonce(64));

  // compute signature
  let string_to_sign = '';
  string_to_sign += host + '\n';
  string_to_sign += embed_path + '\n';
  string_to_sign += json_nonce + '\n';
  string_to_sign += json_time + '\n';
  string_to_sign += json_session_length + '\n';
  string_to_sign += json_external_user_id + '\n';
  string_to_sign += json_permissions + '\n';
  string_to_sign += json_models + '\n';
  string_to_sign += json_group_ids + '\n';
  string_to_sign += json_user_attributes + '\n';
  string_to_sign += json_access_filters;

  let signature = crypto
    .createHmac('sha1', secret)
    .update(forceUnicodeEncoding(string_to_sign))
    .digest('base64')
    .trim();

  // construct query string
  let query_params = {
    nonce: json_nonce,
    time: json_time,
    session_length: json_session_length,
    external_user_id: json_external_user_id,
    permissions: json_permissions,
    models: json_models,
    first_name: json_first_name,
    access_filters: json_access_filters,
    group_ids: json_group_ids,
    user_attributes: json_user_attributes,
    force_logout_login: json_force_logout_login,
    signature: signature,
  };

  let query_string = queryString.stringify(query_params);

  return 'https://' + host + embed_path + '?' + query_string;
};
