import React from 'react';
import styles from './Analytics.module.css';
import { buildEmbedLookerUrl } from './lookerUtils';

function Analytics() {
  return (
    <iframe
      src={buildEmbedLookerUrl()}
      className={styles.analytics}
      title="analytics"
    />
  );
}

export default Analytics;
