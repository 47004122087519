import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth } from './authContext';
import Analytics from './components/Analytics';
import Login from './components/Login';
import Authenticate from './components/Authenticate';

export default function Routes() {
  return (
    <Switch>
      <AuthRoute exact path={['/', '/login']}>
        <Login />
      </AuthRoute>
      <AuthRoute path="/verify-sign-in">
        <Authenticate />
      </AuthRoute>
      <PrivateRoute path="/analytics">
        <Analytics />
      </PrivateRoute>
    </Switch>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function AuthRoute({ children, ...rest }) {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={() => (user ? <Redirect to="/analytics" /> : children)}
    />
  );
}
