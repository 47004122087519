import React, { useEffect } from 'react';
import { useAuth } from '../../authContext';
import { Link } from 'react-router-dom';
import { Spin, Card } from 'antd';

function Authenticate() {
  const { verifySignInLink, error } = useAuth();
  useEffect(() => {
    verifySignInLink();
  }, []);

  if (error)
    return (
      <div className="center">
        <Card>
          <h3>{error}</h3>
          <Link to="/login">Back to Login</Link>
        </Card>
      </div>
    );
  return <Spin className="center" />;
}

export default Authenticate;
