import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [state, setState] = useState({
    status: 'idle',
    error: null,
    user: null,
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase.analytics().setUserProperties({ username: user.email });
        setState({ status: 'success', error: null, user });
      } else {
        setState((prevState) => ({ ...prevState, user: null }));
      }
    });
  }, []);

  const verifySignInLink = () => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem('emailForPaceSignIn');
      if (!email) {
        const error =
          'Your link is no longer valid. Please request a new sign in link.';
        setState({ status: 'error', error, user: null });
        return;
      }

      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function (result) {
          window.localStorage.removeItem('emailForPaceSignIn');
          setState({ status: 'success', error: null, user: result.user });
        })
        .catch(function (error) {
          setState({ status: 'error', error: error.message, user: null });
        });
    }
  };

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        setState({ status: 'idle', error: null, user: null });
      })
      .catch(function (error) {
        setState({ status: 'error', error: error.message, user: null });
      });
  };

  const sendSignInLink = (email) => {
    var actionCodeSettings = {
      url: `${window.location.origin}/verify-sign-in`,
      handleCodeInApp: true,
    };

    return firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
  };
  return (
    <AuthContext.Provider
      value={{ ...state, sendSignInLink, verifySignInLink, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  const isIdle = context.status === 'idle';
  const isError = context.status === 'error';
  const isSuccess = context.status === 'success';
  const isAuthenticated = context.user && isSuccess;
  return {
    ...context,
    isIdle,
    isError,
    isSuccess,
    isAuthenticated,
  };
}

export { AuthProvider, useAuth };
