import React, { useState } from 'react';
import { notification } from 'antd';
import { useAuth } from '../../authContext';
import styles from './Login.module.css';

export default function Login() {
  const [email, setEmail] = useState('');

  const { sendSignInLink } = useAuth();

  const openNotificationWithIcon = (type, message) => {
    notification[type](message);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h3 className={styles.subtitle}>
          <b>No registration needed.</b><br></br>Just click the emailed link.
        </h3>
        <input
          className={styles.form}
          placeholder="Email Address"
          type="email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <button
          onClick={() => {
            sendSignInLink(email)
              .then(() => {
                window.localStorage.setItem('emailForPaceSignIn', email);
                openNotificationWithIcon('success', {
                  message: 'Email sent',
                  description: 'Check your inbox for a sign in link',
                });
              })
              .catch((error) => {
                openNotificationWithIcon('error', {
                  message: 'No email sent',
                  description: error.message,
                });
              });
          }}
          className={styles.button}
          type="primary"
          block="true"
        >
          Send me the link
        </button>
      </div>
    </div>
  );
}
